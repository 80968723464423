@media screen and (max-width:768px) {

    
    .products {
        .lagro-pills {
            li:last-child {
                margin-right: 0;
            }
            li {
                margin-right: 10px;
                a {
                    padding: 8px 20px;
                }
            }
        }
    }
    .product-item {
        margin: 0 auto;
        width: auto!important;
    }
    .book {
        .book-des {
            padding: 30px 30px 30px 30px;
        }
        .flex {
            flex-direction: column;
            .book-img {
                width: 100%;
            }
            .book-des {
                width: 100%;
            }
        }
    }
    .flex {
        flex-direction: column;
        .aboutlagro_min {
            width: 100%;
            padding: 50px 50px 50px 50px;
        }
        .aboutlagro_image {
            width: 100%;
        }
    }
    .lagronav {
        
        padding:10px!important;
        
        .mini_nav{
            display: none;
            text-align: center;
            .logged_in{
                text-align: center;
                margin: 10px 0;
            }
            ul{
                float: none;
                margin: 10px;
            }
        }
        .navbar-collapse {
            margin-top: 10px!important;
        }
        .links {
            float: none!important;
            li {
                a {
                    &:hover {
                        transform: scale(1)!important;
                    }
                }
            }
        }
    }
    .navbar-toggle {
        border: none;
        &:hover,
        &:active,
        &:visited,
        &:focus {
            background-color: transparent!important;
        }
        .icon-bar {
            background-color: white!important;
        }
    }
    .testi {
        padding-bottom: 10px;
    }
    .slick-dots {
        display: none;
    }
    .testi .testimonials {
        margin-bottom: 97px;
        margin-top: 10px;
    }
    .testi .slick-dots {
        top: 117%;
    }
    .product_inner_details_wrapper {
        padding-left: 0;
    }
    .products .products-tab-inner .product-item .overlay-product{
        position: static;
    }
    .lagrobanner .main-slide .content_container {
        h5 {
            font-size: 28px;
        }
        h3 {
            font-size: 40px;
        }
    }
    .slider_related_wrapper {
        .related_left {
            left:15px;
        }
        .related_right {
            right:15px;
        }
    }
    .lagrobanner{
        .main-slide{
            background-position-x: -430px!important;
        }
    }
}

@media screen and (min-width:768px) and (max-width:992px) {
    .product-item {
        width: auto!important;
    }
    .lagro-pills {
        li:last-child {
            margin-right: 0;
        }
        li {
            margin-right: 10px;
            a {
                padding: 8px 20px;
            }
        }
    }
}

@media screen and (max-width:858px) {
    .links {
        li {
            a {
                padding: 15px 8px 15px 8px;
            }
        }
    }
}